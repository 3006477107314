import { FloatButton } from 'antd';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "BackTopstyle__Container",
  componentId: "sc-1cilcae-0"
})(["", " box-shadow:0px 0px 0px 0px rgba(0,0,0,0.05),0px 1px 3px 0px rgba(0,0,0,0.05),0px 5px 5px 0px rgba(0,0,0,0.04),0px 11px 7px 0px rgba(0,0,0,0.03),0px 20px 8px 0px rgba(0,0,0,0.01),0px 31px 9px 0px rgba(0,0,0,0.00);background-blend-mode:soft-light;backdrop-filter:blur(20px);-webkit-backdrop-filter:blur(20px);background:rgba(255,255,255,0.8);"], {
  "position": "absolute",
  "top": "-30px",
  "right": "1.5rem",
  "zIndex": "30",
  "display": "flex",
  "height": "60px",
  "width": "60px",
  "cursor": "pointer",
  "alignItems": "center",
  "justifyContent": "center",
  "overflow": "hidden",
  "borderRadius": "9999px"
});
export const CustomBackTop = styled(FloatButton.BackTop).withConfig({
  displayName: "BackTopstyle__CustomBackTop",
  componentId: "sc-1cilcae-1"
})(["", ""], {
  "zIndex": "60"
});