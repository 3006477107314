import { CdpProfileResponse } from '@modules/libs/graphql/generated/graphql';
import { IUser } from '@modules/user/domain/model/user.model';
import { mapGenderToDomain } from '@shared/helpers/mapGender.helper';
import { convertDate } from '@shared/utils/convertDate.utils';
export class UserDetailEntityMapper {
  public static toDomain(userResponse: CdpProfileResponse): IUser {
    return {
      id: userResponse.uuid,
      firstName: userResponse.firstName,
      lastName: userResponse.lastName,
      gender: mapGenderToDomain(userResponse.gender),
      birthDate: convertDate(userResponse.birthDate * 1000, 'th', 'DD MMM BBBB'),
      email: userResponse.email,
      phoneNumber: userResponse.phoneNumber,
      profileImage: userResponse.imageUrl ?? '',
      point: userResponse.point
    };
  }
}