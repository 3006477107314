export const getCookie = (name: string): string => {
  // Get all cookies as a single string
  const cookies = document.cookie;

  // Split the cookies into individual key-value pairs
  const cookiesArray = cookies.split('; ');

  // Find the specific cookie with the given name
  for (const cookie of cookiesArray) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }

  // Return null if the cookie is not found
  return null;
};
export const setCookie = (name: string, isAccept: boolean, yearPeriod: number) => {
  const expireDate = new Date();
  // expireDate.setMinutes(expireDate.getMinutes() + 3) //[for test] set expired in 3 minutes
  expireDate.setFullYear(expireDate.getFullYear() + yearPeriod);
  const cookieName = `${name}=${+isAccept};`;
  const cookiePath = 'Path=/;';
  const domain = getDomain();
  const cookieDomain = domain ? `Domain=${domain};` : '';
  const cookieExpires = `Expires=${expireDate.toUTCString()};`;
  document.cookie = `${cookieName} ${cookiePath} ${cookieDomain} ${cookieExpires}`;
};
export function deleteCookie(name: string) {
  const cookieName = `${name}=;`;
  const cookiePath = 'Path=/;';
  const domain = getDomain();
  const cookieDomain = domain ? `Domain=${domain};` : '';
  const cookieExpires = 'Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie = `${cookieName} ${cookiePath} ${cookieDomain} ${cookieExpires}`;
}
export function getDomain() {
  const hostname = window?.location?.hostname;
  if (hostname === 'localhost') return null;
  const domain = hostname?.match(/\w*\.\w*$/gi);
  return domain ? `${domain[0]}` : null;
}